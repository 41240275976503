import { registerLocaleData } from '@angular/common';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Inject,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';

import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  MAT_DATE_FORMATS,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatDateFormats,
  RippleGlobalOptions,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  GA_AUTH_CONFIG,
  GaAuthConfig,
  GaAuthModule,
} from '@ga/central-auth-angular';
import { GA_UI_KIT_PROVIDERS } from 'gain-lib/assets/config/src/GA_UI_KIT_CONFIG';
import { BREADCRUMB_TITLE_STRATEGY_PROVIDER } from 'gain-lib/breadcrumbs';
import {
  GainDefaultPrecisionService,
  PrecisionService,
} from 'gain-lib/precision/precision';
import { RuntimeConfigurationModule } from 'gain-web/shared-modules/runtime-configuration/runtime-configuration.module';
import { RuntimeConfigurationService } from 'gain-web/shared-modules/runtime-configuration/runtime-configuration.service';
import { SessionTimeoutDialogModule } from 'gain-web/shared-modules/session-timeout-dialog/session-timeout-dialog.module';
import { SharedEssentialsModule } from 'gain-web/shared-modules/shared-essentials/shared-essentials.module';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { NgChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationInsightsService } from './clients/client-shared/application-insights.service';
import { ErrorHandlerService } from './clients/client-shared/error-handler.service';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';

class ConsoleErrorHandler implements ErrorHandler {
  handleError(error: any) {
    console.error(error);
  }
}

const defaultDateInputFormats: string[] = [
  'l',
  'LL',
  'D MMM YY',
  'DD MMM YY',
  'D MMM YYYY',
  'DD MMM YYYY',
  'MMM. D YYYY',
  'MMM. DD YYYY',
  'MMM. D YY',
  'MMM. DD YY',
  'MMM. D, YYYY',
  'MMM. DD, YYYY',
  'MMM. D, YY',
  'MMM. DD, YY',
  'MMM D, YYYY',
  'MMM DD, YYYY',
  'MMM D, YY',
  'MMM DD, YY',
  'MMM D YYYY',
  'MMM DD YYYY',
  'MMM D YY',
  'MMM DD YY',
  'MMMM D YY',
  'MMMM DD YY',
  'MMMM D YYYY',
  'MMMM DD YYYY',
  'MMMM D, YYYY',
  'MMMM DD, YYYY',
  'MMMM D, YY',
  'MMMM DD, YY',
];

export const US_EN_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: [
      ...defaultDateInputFormats,
      'M/D/YY',
      'M/DD/YY',
      'MM/D/YY',
      'MM/DD/YY',
      'M/D/YYYY',
      'M/DD/YYYY',
      'MM/D/YYYY',
      'MM/DD/YYYY',
      'M-D-YY',
      'M-DD-YY',
      'MM-D-YY',
      'MM-DD-YY',
      'M-D-YYYY',
      'M-DD-YYYY',
      'MM-D-YYYY',
      'MM-DD-YYYY',
      'M.D.YY',
      'M.DD.YY',
      'MM.D.YY',
      'MM.DD.YY',
      'M.D.YYYY',
      'M.DD.YYYY',
      'MM.D.YYYY',
      'MM.DD.YYYY',
      'M D YY',
      'M DD YY',
      'MM D YY',
      'MM DD YY',
      'M D YYYY',
      'M DD YYYY',
      'MM D YYYY',
      'MM DD YYYY',
    ],
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const OTHER_LANG_FORMATS: MatDateFormats = {
  parse: {
    dateInput: [
      ...defaultDateInputFormats,
      'D/M/YY',
      'D/MM/YY',
      'DD/M/YY',
      'DD/MM/YY',
      'D/M/YYYY',
      'D/MM/YYYY',
      'DD/M/YYYY',
      'DD/MM/YYYY',
      'D-M-YY',
      'D-MM-YY',
      'DD-M-YY',
      'DD-MM-YY',
      'D-M-YYYY',
      'D-MM-YYYY',
      'DD-M-YYYY',
      'DD-MM-YYYY',
      'D.M.YY',
      'D.MM.YY',
      'DD.M.YY',
      'DD.MM.YY',
      'D.M.YYYY',
      'D.MM.YYYY',
      'DD.M.YYYY',
      'DD.MM.YYYY',
      'D M YY',
      'D MM YY',
      'DD M YY',
      'DD MM YY',
      'D M YYYY',
      'D MM YYYY',
      'DD M YYYY',
      'DD MM YYYY',
    ],
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

function initApp(
  applicationInsights: ApplicationInsightsService,
  config: RuntimeConfigurationService,
): () => Promise<void> {
  return async () => {
    await config.init();
    applicationInsights.init();
  };
}

function getApiBaseUrl(config: RuntimeConfigurationService): string {
  const { apiHostMode, localApiUrl, apiUrl } = config.get();
  if (apiHostMode === 'local' && localApiUrl == null) {
    throw new Error(
      `API host mode set to ${apiHostMode}, but no local API url provided in configuration.`,
    );
  }
  const url: string = apiHostMode === 'local' ? localApiUrl! : apiUrl;
  if (apiHostMode != null) {
    // eslint-disable-next-line no-console
    console.debug(`API host mode set to '${apiHostMode}'.`);
  }
  // eslint-disable-next-line no-console
  console.debug(`Using ${url} to connect to API.`);
  return url;
}

function getGaAuthConfig(config: RuntimeConfigurationService): GaAuthConfig {
  const authOptions = config.get().authOptions;
  return {
    gaAuthApiUrl: authOptions.apiUrl,
    devOptions: {
      disableAuth: !authOptions.enabled,
      useDevApi: !authOptions.isUpperEnvironment,
    },
  };
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    UnauthorizedPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedEssentialsModule,
    RuntimeConfigurationModule,
    GaAuthModule,
    SessionTimeoutDialogModule,
    MatDatepickerModule,
    MatInputModule,
    MatMomentDateModule,
    NgChartsModule,
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: GA_AUTH_CONFIG,
      useFactory: getGaAuthConfig,
      deps: [RuntimeConfigurationService],
    },
    {
      provide: ApiClient.API_BASE_URL,
      useFactory: getApiBaseUrl,
      deps: [RuntimeConfigurationService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [ApplicationInsightsService, RuntimeConfigurationService],
      multi: true,
    },
    GA_UI_KIT_PROVIDERS,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: true,
        strict: true,
      },
    },
    { provide: LOCALE_ID, useValue: navigator.language },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (locale: string) => {
        if (locale === 'en-US') {
          return US_EN_DATE_FORMATS;
        } else {
          return OTHER_LANG_FORMATS;
        }
      },
      deps: [LOCALE_ID],
    },
    BREADCRUMB_TITLE_STRATEGY_PROVIDER,
    ApplicationInsightsService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    { provide: ErrorHandler, useClass: ConsoleErrorHandler },
    { provide: PrecisionService, useClass: GainDefaultPrecisionService },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(LOCALE_ID) public locale: string) {
    AppModule.localePromise = new Promise((resolve, reject) => {
      function tryResolveModule(moduleId: string) {
        const modulePromise: Promise<any> = import(
          `../../../../node_modules/@angular/common/locales/${moduleId}.mjs`
        );
        modulePromise
          .then((module) => {
            registerLocaleData(module.default);
            AppModule.localeLoaded = true;
            resolve(module);
          })
          .catch((e) => {
            reject(e);
          });
      }

      try {
        tryResolveModule(locale);
      } catch (firstError) {
        const moduleID = locale.substring(0, locale.indexOf('-'));
        try {
          tryResolveModule(moduleID);
        } catch (secondError) {
          reject(secondError);
        }
      }
    });
  }

  static localeLoaded: boolean = false;
  static localePromise: Promise<any>;
}
