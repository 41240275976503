import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationStart,
  Params,
  Router,
  TitleStrategy,
} from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';

declare const initialPageLoadTimestamp: number;
export type PageView = {
  title?: string;
  routePath: string;
  urlPath: string;
  url: string;
  durationInMs: number;
  initialLoad: boolean;
  initialLoadDurationInMs?: number;
  initialLoadOverheadInMs?: number;
  start: NavigationStart;
  end: NavigationEnd;
  routeParams: Params;
  queryParams: Params;
};

@Injectable({ providedIn: 'root' })
export class RouterPageViewService {
  private _pageViews = new Subject<PageView>();
  public get pageViews$(): Observable<PageView> {
    return this._pageViews.asObservable();
  }
  private static _sub: Subscription;

  constructor(router: Router, titleStrategy: TitleStrategy) {
    if (RouterPageViewService._sub == null) {
      let start:
        | { event: NavigationStart; timestamp: number; isFirst: boolean }
        | undefined;
      RouterPageViewService._sub = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          const isFirst = start == null;
          start = {
            event,
            isFirst,
            timestamp: performance.now(),
          };
        } else if (
          event instanceof NavigationEnd &&
          start != null &&
          start.event.url === event.url
        ) {
          const url = window.location.href;
          const title = titleStrategy.buildTitle(router.routerState.snapshot);
          let ss: ActivatedRouteSnapshot | null =
            router.routerState.snapshot.root;
          const paths: string[] = [];
          const params: Params[] = [];
          const queryParams: Params[] = [];

          while (ss != null) {
            params.push(ss.params);
            queryParams.push(ss.queryParams);
            if (ss.routeConfig != null) {
              const path = ss.routeConfig.path;
              if (path != null && path !== '') {
                paths.push(path);
              }
            }
            ss = ss.firstChild;
          }

          const path = '/' + paths.join('/');

          const durationInMs = performance.now() - start.timestamp;
          const initialLoad = start.isFirst;
          const initialLoadDurationInMs = initialLoad
            ? performance.now() - initialPageLoadTimestamp
            : undefined;
          const initialLoadOverheadInMs =
            initialLoadDurationInMs != null
              ? initialLoadDurationInMs - durationInMs
              : undefined;
          this._pageViews.next({
            durationInMs,
            routePath: path,
            title,
            urlPath: event.url,
            url,
            initialLoad,
            initialLoadDurationInMs,
            initialLoadOverheadInMs,
            start: start.event,
            end: event,
            routeParams: params.reduce((p1, p2) => ({ ...p1, ...p2 })),
            queryParams: queryParams.reduce((p1, p2) => ({ ...p1, ...p2 })),
          });
        }
      });
    }
  }
}
