import { FactoryProvider } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Breadcrumbs } from './breadcrumbs';

export class BreadcrumbTitleStrategy extends TitleStrategy {
  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title != null) {
      this._title.setTitle(title);
    }
  }
  override buildTitle(snapshot: RouterStateSnapshot): string | undefined {
    return this._breadcrumbs.getTitle(snapshot, this._title);
  }

  constructor(
    private _breadcrumbs: Breadcrumbs,
    private _title: Title,
  ) {
    super();
  }
}

function breadcrumbTitleStrategyFactory(
  breadcrumbs: Breadcrumbs,
  title: Title,
) {
  return new BreadcrumbTitleStrategy(breadcrumbs, title);
}

export const BREADCRUMB_TITLE_STRATEGY_PROVIDER: FactoryProvider = {
  provide: TitleStrategy,
  useFactory: breadcrumbTitleStrategyFactory,
  deps: [Breadcrumbs, Title],
};
