/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if (!environment.production) {
      const styled = (message: string, ...styles: string[]) =>
        `\x1B[` + styles.join(';') + `m` + message + `\x1B[m`;
      // eslint-disable-next-line no-console
      console.log(
        '%cWelcome to GAIN! ✈🚂🚗🌍🌏🌎💵📈💴💱💷💸💸💸',
        'font-size:20px',
      );
      // eslint-disable-next-line no-console
      console.log(`👩‍💻💪 ${styled('Development Tips', '1')} 💻👨‍💻

✅ Run ${styled('npm run start-sandbox', '47')} to see a variety of example reference implementations of common patterns to aid your development 🧱
✅ Use the ${styled('GAIN UIUX checklist', '1')} (https://bit.ly/gain-uiux-checklist) to ensure designs and implementations are consistent and aligned with the GA Design System 📋
✅ Follow the ${styled('GAIN Angular Development Guidelines', '1')} (https://bit.ly/gain-angular-dev-guidelines) for guidance on architecture, code style, and helpful TypeScript/Angular techniques 🧾
✅ Leverage the ${styled('Learning Angular for GAIN', '1')} guide (https://bit.ly/learn-angular-for-gain) to get up-to-speed quickly on Angular 🏫

Happy coding!`);
    }
  })
  .catch((err) => console.error(err));
